<template>
   <header class="header lock-padding" id="header">
      <div class="container">
         <div class="header__row">
            <router-link to="/" class="header__logo">
               <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M28.3806 9.27218H19.6084V0.5M28.3806 20.1084H19.6084V28.8806M0 9.27218H8.77218V0.5M0 20.1084H8.77218V28.8806"
                     stroke="white" stroke-width="3.61207" />
               </svg>
               <span>PROTENDER</span>
            </router-link>
            <ul class="header__list">
               <li>
                  <router-link to="/profile/search" class="header__link header-search"><span>Поиск</span></router-link>
               </li>
               <li>
                  <router-link to="/profile/favorite" class="header__link">
                     Избранные
                  </router-link>
               </li>
               <li>
                  <router-link to="/profile/escort" class="header__link">
                     Сопровождение</router-link>
               </li>
               <li>
                  <router-link to="/profile/instructions" class="header__link">
                     Инструкции</router-link>
               </li>
               <li>
                  <router-link to="/profile/videos" class="header__link">
                     Видеокурс по закупкам
                  </router-link>
               </li>
            </ul>
            <div class="header__body header-body" :class="{ active: menumobile }">
               <div class="header__menu header-menu">
                  <div class="header-menu__title">Меню</div>
                  <ul class="header-menu__list">
                     <li>
                        <a class="header-menu__link" @click="GoToMob('/profile/search')">Поиск</a>
                     </li>
                     <li>
                        <a class="header-menu__link" @click="GoToMob('/profile/favorite')">Избранные</a>
                     </li>
                     <li>
                        <a class="header-menu__link" @click="GoToMob('/profile/escort')">Сопровождение</a>
                     </li>
                     <li>
                        <a class="header-menu__link" @click="GoToMob('/profile/instructions')">Инструкции</a>
                     </li>
                     <li>
                        <a class="header-menu__link" @click="GoToMob('/profile/videos')">Видеокурс по закупкам</a>
                     </li>
                  </ul>
                  <a @click="logout()" class="header-menu__sign modal__link">Выйти</a>
                  <a href="mailto:i@protender.pro" class="header-menu__mail">
                     <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.6 18.5L17.37 10.73H0.75V8.27H17.37L9.6 0.499999H13.05L22.05 9.5L13.05 18.5H9.6Z"
                           fill="white" />
                     </svg>
                     <span>i@protender.pro</span>
                  </a>
               </div>
               <div class="header__copy header-copy">
                  <div class="header-copy__item">© PROTENDER, 2023</div>
                  <a href="https://protender.pro/policy" target="_blank" class="header-copy__link">Политика
                     конфиденциальности</a>
               </div>
            </div>
            <ul class="header-account">
               <li class="header-account__not header-not">
                  <a id="not1" @click="Notification()" class="header-not__link">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M10 21H14C14 22.1 13.1 23 12 23C10.9 23 10 22.1 10 21ZM21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2C13.1 2 14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19ZM17 11C17 8.2 14.8 6 12 6C9.2 6 7 8.2 7 11V18H17V11Z"
                           fill="white" />
                     </svg>
                     <span class="header-not__count" :class="{ hidden: isHiddenNot }"></span>
                  </a>
                  <a @click="NotificationMob()" class="modal__link header-not__link_adapt">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M10 21H14C14 22.1 13.1 23 12 23C10.9 23 10 22.1 10 21ZM21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2C13.1 2 14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19ZM17 11C17 8.2 14.8 6 12 6C9.2 6 7 8.2 7 11V18H17V11Z"
                           fill="white" />
                     </svg>
                  </a>
                  <div :class="{ outside: notification }" @click="notification = false"></div>
                  <div class="notifications notifications_pc" :class="{ active: notification }">
                     <ul class="notifications__list" v-if="notifications.length">
                        <li v-for="item in notifications" :key="item">
                           <div class="notification orange">
                              <div class="notification__title">
                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M8.00065 13.3335C9.41514 13.3335 10.7717 12.7716 11.7719 11.7714C12.7721 10.7712 13.334 9.41465 13.334 8.00016C13.334 6.58567 12.7721 5.22912 11.7719 4.22893C10.7717 3.22873 9.41514 2.66683 8.00065 2.66683C6.58616 2.66683 5.22961 3.22873 4.22941 4.22893C3.22922 5.22912 2.66732 6.58567 2.66732 8.00016C2.66732 9.41465 3.22922 10.7712 4.22941 11.7714C5.22961 12.7716 6.58616 13.3335 8.00065 13.3335ZM8.00065 1.3335C8.87613 1.3335 9.74304 1.50593 10.5519 1.84097C11.3607 2.176 12.0956 2.66706 12.7147 3.28612C13.3338 3.90517 13.8248 4.6401 14.1598 5.44894C14.4949 6.25778 14.6673 7.12468 14.6673 8.00016C14.6673 9.76827 13.9649 11.464 12.7147 12.7142C11.4645 13.9645 9.76876 14.6668 8.00065 14.6668C4.31398 14.6668 1.33398 11.6668 1.33398 8.00016C1.33398 6.23205 2.03636 4.53636 3.28661 3.28612C4.53685 2.03588 6.23254 1.3335 8.00065 1.3335ZM8.33398 4.66683V8.16683L11.334 9.94683L10.834 10.7668L7.33398 8.66683V4.66683H8.33398Z"
                                       fill="#E76014" />
                                 </svg>
                                 <span>18 ч. до завершение приема заявок</span>
                              </div>
                              <div class="notification__text">{{ item.text }}</div>
                              <div class="notification__time">24.04.2023, 23:54</div>
                           </div>
                        </li>
                     </ul>
                     <ul class="notifications__list" v-else>
                        <!-- pc notifications------------------ -->
                        <li>
                           <p style="color: #fff">Уведомлений пока нет</p>
                        </li>
                     </ul>
                     <a @click="GoLinkRef('notificationSettings')" class="notifications__link">Настроить уведомления</a>
                  </div>
                  <div class="modal notifications notifications_mobile" :class="{ open: notificationMob }"
                     id="notifications" @click="notificationMob = false">
                     <div class="modal__content notifications__content" @click.stop="">
                        <div class="notifications__row">
                           <div class="notifications__title account-title">
                              Уведомления
                           </div>
                           <div class="modal__close notifications__close" @click="notificationMob = false">
                              <img src="@/assets/img/icons/notifications__close.svg" alt="" />
                           </div>
                        </div>
                        <!--                   <ul class="notifications__list">
                     <li>
                        <div class="notification orange">
                           <div class="notification__row">
                              <div class="notification__title">
                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M8.00065 13.3335C9.41514 13.3335 10.7717 12.7716 11.7719 11.7714C12.7721 10.7712 13.334 9.41465 13.334 8.00016C13.334 6.58567 12.7721 5.22912 11.7719 4.22893C10.7717 3.22873 9.41514 2.66683 8.00065 2.66683C6.58616 2.66683 5.22961 3.22873 4.22941 4.22893C3.22922 5.22912 2.66732 6.58567 2.66732 8.00016C2.66732 9.41465 3.22922 10.7712 4.22941 11.7714C5.22961 12.7716 6.58616 13.3335 8.00065 13.3335ZM8.00065 1.3335C8.87613 1.3335 9.74304 1.50593 10.5519 1.84097C11.3607 2.176 12.0956 2.66706 12.7147 3.28612C13.3338 3.90517 13.8248 4.6401 14.1598 5.44894C14.4949 6.25778 14.6673 7.12468 14.6673 8.00016C14.6673 9.76827 13.9649 11.464 12.7147 12.7142C11.4645 13.9645 9.76876 14.6668 8.00065 14.6668C4.31398 14.6668 1.33398 11.6668 1.33398 8.00016C1.33398 6.23205 2.03636 4.53636 3.28661 3.28612C4.53685 2.03588 6.23254 1.3335 8.00065 1.3335ZM8.33398 4.66683V8.16683L11.334 9.94683L10.834 10.7668L7.33398 8.66683V4.66683H8.33398Z"
                                       fill="#E76014" />
                                 </svg>
                                 <span>18 ч. до завершение приема заявок</span>
                              </div>
                              <div class="notification__time">24.04.2023, 23:54</div>
                           </div>
                           <div class="notification__text">(ПКР-011625-23) Выполнение работ по разработке проектной документации по
                              капитальному ремонту общего имущества многоквартирного(ых) дома(ов) и завершение работ по капитальному
                              ремонту общего имущества в многокварт…</div>
                           <div class="notification__time adapt">24.04.2023, 23:54</div>
                        </div>
                     </li>
                     <li>
                        <div class="notification green">
                           <div class="notification__row">
                              <div class="notification__title">
                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M8.00065 13.3335C9.41514 13.3335 10.7717 12.7716 11.7719 11.7714C12.7721 10.7712 13.334 9.41465 13.334 8.00016C13.334 6.58567 12.7721 5.22912 11.7719 4.22893C10.7717 3.22873 9.41514 2.66683 8.00065 2.66683C6.58616 2.66683 5.22961 3.22873 4.22941 4.22893C3.22922 5.22912 2.66732 6.58567 2.66732 8.00016C2.66732 9.41465 3.22922 10.7712 4.22941 11.7714C5.22961 12.7716 6.58616 13.3335 8.00065 13.3335ZM8.00065 1.3335C8.87613 1.3335 9.74304 1.50593 10.5519 1.84097C11.3607 2.176 12.0956 2.66706 12.7147 3.28612C13.3338 3.90517 13.8248 4.6401 14.1598 5.44894C14.4949 6.25778 14.6673 7.12468 14.6673 8.00016C14.6673 9.76827 13.9649 11.464 12.7147 12.7142C11.4645 13.9645 9.76876 14.6668 8.00065 14.6668C4.31398 14.6668 1.33398 11.6668 1.33398 8.00016C1.33398 6.23205 2.03636 4.53636 3.28661 3.28612C4.53685 2.03588 6.23254 1.3335 8.00065 1.3335ZM8.33398 4.66683V8.16683L11.334 9.94683L10.834 10.7668L7.33398 8.66683V4.66683H8.33398Z"
                                       fill="#E76014" />
                                 </svg>
                                 <span>18 ч. до завершение приема заявок</span>
                              </div>
                              <div class="notification__time">24.04.2023, 23:54</div>
                           </div>
                           <div class="notification__text">(ПКР-011625-23) Выполнение работ по разработке проектной документации по
                              капитальному ремонту общего имущества многоквартирного(ых) дома(ов) и завершение работ по капитальному
                              ремонту общего имущества в многокварт…</div>
                           <div class="notification__time adapt">24.04.2023, 23:54</div>
                        </div>
                     </li>
                     <li>
                        <div class="notification yellow">
                           <div class="notification__row">
                              <div class="notification__title">
                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M8.00065 13.3335C9.41514 13.3335 10.7717 12.7716 11.7719 11.7714C12.7721 10.7712 13.334 9.41465 13.334 8.00016C13.334 6.58567 12.7721 5.22912 11.7719 4.22893C10.7717 3.22873 9.41514 2.66683 8.00065 2.66683C6.58616 2.66683 5.22961 3.22873 4.22941 4.22893C3.22922 5.22912 2.66732 6.58567 2.66732 8.00016C2.66732 9.41465 3.22922 10.7712 4.22941 11.7714C5.22961 12.7716 6.58616 13.3335 8.00065 13.3335ZM8.00065 1.3335C8.87613 1.3335 9.74304 1.50593 10.5519 1.84097C11.3607 2.176 12.0956 2.66706 12.7147 3.28612C13.3338 3.90517 13.8248 4.6401 14.1598 5.44894C14.4949 6.25778 14.6673 7.12468 14.6673 8.00016C14.6673 9.76827 13.9649 11.464 12.7147 12.7142C11.4645 13.9645 9.76876 14.6668 8.00065 14.6668C4.31398 14.6668 1.33398 11.6668 1.33398 8.00016C1.33398 6.23205 2.03636 4.53636 3.28661 3.28612C4.53685 2.03588 6.23254 1.3335 8.00065 1.3335ZM8.33398 4.66683V8.16683L11.334 9.94683L10.834 10.7668L7.33398 8.66683V4.66683H8.33398Z"
                                       fill="#E76014" />
                                 </svg>
                                 <span>18 ч. до завершение приема заявок</span>
                              </div>
                              <div class="notification__time">24.04.2023, 23:54</div>
                           </div>
                           <div class="notification__text">(ПКР-011625-23) Выполнение работ по разработке проектной документации по
                              капитальному ремонту общего имущества многоквартирного(ых) дома(ов) и завершение работ по капитальному
                              ремонту общего имущества в многокварт…</div>
                           <div class="notification__time adapt">24.04.2023, 23:54</div>
                        </div>
                     </li>
                     <li>
                        <div class="notification green">
                           <div class="notification__row">
                              <div class="notification__title">
                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M8.00065 13.3335C9.41514 13.3335 10.7717 12.7716 11.7719 11.7714C12.7721 10.7712 13.334 9.41465 13.334 8.00016C13.334 6.58567 12.7721 5.22912 11.7719 4.22893C10.7717 3.22873 9.41514 2.66683 8.00065 2.66683C6.58616 2.66683 5.22961 3.22873 4.22941 4.22893C3.22922 5.22912 2.66732 6.58567 2.66732 8.00016C2.66732 9.41465 3.22922 10.7712 4.22941 11.7714C5.22961 12.7716 6.58616 13.3335 8.00065 13.3335ZM8.00065 1.3335C8.87613 1.3335 9.74304 1.50593 10.5519 1.84097C11.3607 2.176 12.0956 2.66706 12.7147 3.28612C13.3338 3.90517 13.8248 4.6401 14.1598 5.44894C14.4949 6.25778 14.6673 7.12468 14.6673 8.00016C14.6673 9.76827 13.9649 11.464 12.7147 12.7142C11.4645 13.9645 9.76876 14.6668 8.00065 14.6668C4.31398 14.6668 1.33398 11.6668 1.33398 8.00016C1.33398 6.23205 2.03636 4.53636 3.28661 3.28612C4.53685 2.03588 6.23254 1.3335 8.00065 1.3335ZM8.33398 4.66683V8.16683L11.334 9.94683L10.834 10.7668L7.33398 8.66683V4.66683H8.33398Z"
                                       fill="#E76014" />
                                 </svg>
                                 <span>18 ч. до завершение приема заявок</span>
                              </div>
                              <div class="notification__time">24.04.2023, 23:54</div>
                           </div>
                           <div class="notification__text">(ПКР-011625-23) Выполнение работ по разработке проектной документации по
                              капитальному ремонту общего имущества многоквартирного(ых) дома(ов) и завершение работ по капитальному
                              ремонту общего имущества в многокварт…</div>
                           <div class="notification__time adapt">24.04.2023, 23:54</div>
                        </div>
                     </li>
                     <li>
                        <div class="notification yellow">
                           <div class="notification__row">
                              <div class="notification__title">
                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M8.00065 13.3335C9.41514 13.3335 10.7717 12.7716 11.7719 11.7714C12.7721 10.7712 13.334 9.41465 13.334 8.00016C13.334 6.58567 12.7721 5.22912 11.7719 4.22893C10.7717 3.22873 9.41514 2.66683 8.00065 2.66683C6.58616 2.66683 5.22961 3.22873 4.22941 4.22893C3.22922 5.22912 2.66732 6.58567 2.66732 8.00016C2.66732 9.41465 3.22922 10.7712 4.22941 11.7714C5.22961 12.7716 6.58616 13.3335 8.00065 13.3335ZM8.00065 1.3335C8.87613 1.3335 9.74304 1.50593 10.5519 1.84097C11.3607 2.176 12.0956 2.66706 12.7147 3.28612C13.3338 3.90517 13.8248 4.6401 14.1598 5.44894C14.4949 6.25778 14.6673 7.12468 14.6673 8.00016C14.6673 9.76827 13.9649 11.464 12.7147 12.7142C11.4645 13.9645 9.76876 14.6668 8.00065 14.6668C4.31398 14.6668 1.33398 11.6668 1.33398 8.00016C1.33398 6.23205 2.03636 4.53636 3.28661 3.28612C4.53685 2.03588 6.23254 1.3335 8.00065 1.3335ZM8.33398 4.66683V8.16683L11.334 9.94683L10.834 10.7668L7.33398 8.66683V4.66683H8.33398Z"
                                       fill="#E76014" />
                                 </svg>
                                 <span>18 ч. до завершение приема заявок</span>
                              </div>
                              <div class="notification__time">24.04.2023, 23:54</div>
                           </div>
                           <div class="notification__text">(ПКР-011625-23) Выполнение работ по разработке проектной документации по
                              капитальному ремонту общего имущества многоквартирного(ых) дома(ов) и завершение работ по капитальному
                              ремонту общего имущества в многокварт…</div>
                           <div class="notification__time adapt">24.04.2023, 23:54</div>
                        </div>
                     </li>
                     <li>
                        <div class="notification orange">
                           <div class="notification__row">
                              <div class="notification__title">
                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M8.00065 13.3335C9.41514 13.3335 10.7717 12.7716 11.7719 11.7714C12.7721 10.7712 13.334 9.41465 13.334 8.00016C13.334 6.58567 12.7721 5.22912 11.7719 4.22893C10.7717 3.22873 9.41514 2.66683 8.00065 2.66683C6.58616 2.66683 5.22961 3.22873 4.22941 4.22893C3.22922 5.22912 2.66732 6.58567 2.66732 8.00016C2.66732 9.41465 3.22922 10.7712 4.22941 11.7714C5.22961 12.7716 6.58616 13.3335 8.00065 13.3335ZM8.00065 1.3335C8.87613 1.3335 9.74304 1.50593 10.5519 1.84097C11.3607 2.176 12.0956 2.66706 12.7147 3.28612C13.3338 3.90517 13.8248 4.6401 14.1598 5.44894C14.4949 6.25778 14.6673 7.12468 14.6673 8.00016C14.6673 9.76827 13.9649 11.464 12.7147 12.7142C11.4645 13.9645 9.76876 14.6668 8.00065 14.6668C4.31398 14.6668 1.33398 11.6668 1.33398 8.00016C1.33398 6.23205 2.03636 4.53636 3.28661 3.28612C4.53685 2.03588 6.23254 1.3335 8.00065 1.3335ZM8.33398 4.66683V8.16683L11.334 9.94683L10.834 10.7668L7.33398 8.66683V4.66683H8.33398Z"
                                       fill="#E76014" />
                                 </svg>
                                 <span>18 ч. до завершение приема заявок</span>
                              </div>
                              <div class="notification__time">24.04.2023, 23:54</div>
                           </div>
                           <div class="notification__text">(ПКР-011625-23) Выполнение работ по разработке проектной документации по
                              капитальному ремонту общего имущества многоквартирного(ых) дома(ов) и завершение работ по капитальному
                              ремонту общего имущества в многокварт…</div>
                           <div class="notification__time adapt">24.04.2023, 23:54</div>
                        </div>
                     </li>
                  </ul> -->
                        <ul class="notifications__list">
                           <!-- mobile notifications------------------ -->
                           <li>
                              <p style="color: #fff">Уведомлений пока нет</p>
                           </li>
                        </ul>
                        <a @click="GoLinkRef('notificationSettings')" class="notifications__link">Настроить
                           уведомления</a>
                     </div>
                  </div>
               </li>
               <li class="header-account__activities">
                  <router-link to="/profile" class="header-account__user user-width">
                     <div class="header-account__image" v-if="Object.keys(user).length > 0">
                        <img v-if="user.get_image" :src="user.get_image" />
                        <img v-else src="@/assets/img/account/header__clear.svg" alt="" />
                     </div>
                     <span v-if="Object.keys(user).length > 0">{{
                        initials(user.fio)
                     }}</span>
                  </router-link>
                  <a @click="logout()" class="header-account__exit">
                     <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                           d="M2.66659 2.66683C2.66659 1.93045 3.26354 1.3335 3.99992 1.3335H11.9999C12.7363 1.3335 13.3333 1.93045 13.3333 2.66683V13.3335C13.3333 14.0699 12.7363 14.6668 11.9999 14.6668H3.99992C3.26354 14.6668 2.66659 14.0699 2.66659 13.3335V10.3079H3.99992V13.3335H11.9999L11.9999 2.66683L3.99992 2.66683V5.69247H2.66659V2.66683Z"
                           fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                           d="M7.13799 4.86209L10.2761 8.00016L7.13799 11.1382L6.19518 10.1954L7.72378 8.66683H1.33325V7.3335H7.72378L6.19518 5.8049L7.13799 4.86209Z"
                           fill="white" />
                     </svg>
                  </a>
               </li>
            </ul>

            <div class="header__burger burger-btn" :class="{ active: menumobile }" @click="openMobileMenu()">
               <button><span></span></button>
               <span class="show">Меню</span>
               <span class="close">Закрыть</span>
            </div>
         </div>
      </div>
   </header>

   <auth-modal :openLog="openLog" v-on:close="openLog = $event"></auth-modal>

   <!-- <div id="oneum-chat"></div> -->
</template>

<style scoped>
.user-width {
   width: 160px;
}

.user-width {
   opacity: 0;
   animation: ani 1s forwards;
}

@keyframes ani {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}
</style>

<script>
import Modal from "@/components/Auth/Modal";
import axios from "axios";
// import { onMounted, ref } from 'vue';
import { onMounted, ref } from "@vue/runtime-core";
// import oneum from "@/mixins/oneum";
import { getList } from "@/assets/api/notifications";



export default {
   name: "ProfileHeader",
   data() {
      return {
         notification: false,
         notificationMob: false,
         menumobile: false,
         showmenu: false,
         showmenushop: false,
         showmodallogout: false,
         status: false,
         openLog: false,

         user: {},
         loader: true,



         notifications: [],
         isHiddenNot: true,
      };
   },
   props: {
      user__profile: {
         type: Boolean,
         default: false,
      },
   },
   // setup(data) {
   //    onMounted(() => {

   //    });
   // },
   methods: {
      async GetMeInfo() {
         await axios
            .get("/api/v1/me/")
            .then((response) => {
               this.user = response.data;
               console.log("test", response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      OpenLogIn() {
         this.openLog = true;
      },
      Notification() {
         this.notification = !this.notification;
         if (this.notification) {
            this.isHiddenNot = true
         }
      },
      NotificationMob() {
         this.notificationMob = !this.notificationMob;
      },
      openMobileMenu() {
         this.menumobile = !this.menumobile;
      },
      GoToMob(value) {
         this.$router.push(value);
         this.menumobile = false;
      },
      GoLinkRef(value) {
         this.$router.push({ path: "/profile", query: { redirect: value } });
         this.menumobile = false;
         this.notificationMob = false;
      },
      initials(str) {
         return str
            .split(/\s+/)
            .map((w, i) => (i ? w.substring(0, 1).toUpperCase() + "." : w))
            .join(" ");
      },
      logout() {
         axios.defaults.headers.common["Authorization"] = "";

         localStorage.removeItem("token");
         localStorage.removeItem("user_id");
         localStorage.removeItem("sub_id");
         localStorage.removeItem("user");

         this.$store.commit("removeUser");
         this.$store.commit("removeUserInfo");
         this.$store.commit("removeToken");
         this.$store.commit("removeSub");

         this.$router.push("/");
      },
      async getNotifications() {
         let { results } = await getList()
         this.notifications = results;
      },
   },
   components: {
      "auth-modal": Modal,
   },
   mounted() {
      // this.notificationsWatcher()
      // window.addEventListener('scroll', this.notificationsWatcher);
   },
   beforeUnmount() {
      // window.removeEventListener('scroll', this.notificationsWatcher);

   },
   async created() {
      await this.GetMeInfo();
      await this.getNotifications()
   },
};
</script>
