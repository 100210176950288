<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="hero">
         <div class="container">
            <div class="hero__card">
               <div class="hero__title title">Избранные</div>
               <div class="hero__main" v-if="favoriteItems.length != 0">
                  <div class="hero-tags">
                     <span class="favor__text hero__subtitle">Метки</span>
                     <ul class="hero-tags__list">
                        <li class="checkbox__item" v-for="item in favObjects" :key="item.id">
                           <input type="checkbox" :id="'favorTags' + item.id" v-model="statusAr" :value="item.id" />
                           <label :for="'favorTags' + item.id"></label>
                           <span class="favor__text">{{ item.name }}</span>
                        </li>
                        <!-- <li class="checkbox__item">
                           <input type="checkbox" id="favorTags1" v-model="statusAr" value="4" />
                           <label for="favorTags1"></label>
                           <span class="favor__text">Анализировать</span>
                        </li>
                        <li class="checkbox__item">
                           <input type="checkbox" id="favorTags2" v-model="statusAr" value="3" />
                           <label for="favorTags2"></label>
                           <span class="favor__text">Участвовать</span>
                        </li>
                        <li class="checkbox__item">
                           <input type="checkbox" id="favorTags3" v-model="statusAr" value="2" />
                           <label for="favorTags3"></label>
                           <span class="favor__text">Выиграли</span>
                        </li>
                        <li class="checkbox__item">
                           <input type="checkbox" id="favorTags4" v-model="statusAr" value="1" />
                           <label for="favorTags4"></label>
                           <span class="favor__text">Субподряд</span>
                        </li> -->
                     </ul>
                  </div>
                  <!--                   <div class="hero-status">
                     <span class="favor__text hero__subtitle">Статус закупки:</span>
                     <ul class="hero-status__list">
                        <li class="checkbox__item">
                           <input type="checkbox" id="favorStatus1">
                           <label for="favorStatus1"></label>
                           <span class="favor__text">Завершенная</span>
                        </li>
                        <li class="checkbox__item">
                           <input type="checkbox" id="favorStatus2">
                           <label for="favorStatus2"></label>
                           <span class="favor__text">Не завершенная</span>
                        </li>
                     </ul>
                  </div> -->
                  <!--                   <div class="hero-date">
                     <div class="hero-date__title favor__text">Дата окончания приема заявок</div>
                     <div class="hero-date__row">
                        <div class="search__input_wrapper">
                           <input class="search__input" type="number" placeholder="от" id="date15">
                           <label for="date15">
                              <img src="@/assets/img/icons/calendar.svg" alt="">
                           </label>
                        </div>
                        <div class="search__input_wrapper">
                           <input class="search__input" type="number" placeholder="до" id="date16">
                           <label for="date16">
                              <img src="@/assets/img/icons/calendar.svg" alt="">
                           </label>
                        </div>
                     </div>
                  </div> -->
               </div>
            </div>
         </div>
      </section>
      <section class="tenders" v-if="favoriteItems.length != 0">
         <div class="container">
            <div class="tenders__wrapper">
               <div class="tenders-main">
                  <div class="tenders-main__row">
                     <!-- <div class="tenders-main__sort">
                        <span>Сортировать:</span>
                        <v-select :settings="sortSettings" @selectOption="selectSort" />
                     </div> -->
                     <!-- <div class="tenders-main__sort  ">
                        <span>Сортировать:</span>
                        <form>
                           <div class="tenders-select" data-state="">
                              <div class="tenders-select__title" data-default="Сначала новые">Сначала новые</div>
                              <div class="tenders-select__content">
                                 <input id="singleSelect0" class="tenders-select__input" type="radio"
                                    name="singleSelect" checked />
                                 <label for="singleSelect0" class="tenders-select__label">Сначала новые</label>
                                 <input id="singleSelect1" class="tenders-select__input" type="radio"
                                    name="singleSelect" />
                                 <label for="singleSelect1" class="tenders-select__label">Сначала новые</label>
                                 <input id="singleSelect2" class="tenders-select__input" type="radio"
                                    name="singleSelect" />
                                 <label for="singleSelect2" class="tenders-select__label">Сначала новые 1</label>
                                 <input id="singleSelect3" class="tenders-select__input" type="radio"
                                    name="singleSelect" />
                                 <label for="singleSelect3" class="tenders-select__label">Сначала новые 2</label>
                              </div>
                           </div>
                        </form>
                     </div> -->
                     <download-excel class="tenders-main__save tenders__text_small" :data="dataExcel"
                        :fields="json_fields" type="xlsx" worksheet="Данные" name="search.xlsx">
                        Сохранить в Excel
                     </download-excel>
                  </div>
                  <ul class="tenders-main__list">
                     <li class="tenders-main__item tender" v-for="(list, index) in favoriteItems" :key="index" :class="{
                        green: list.status == 3,
                        yellow: list.status == 2,
                        violet: list.status == 1,
                        blue: list.status == 4 || (list.status !== 1 && list.status !== 2 && list.status !== 3),

                     }">
                        <header class="tender-header">
                           <div class="outside" v-if="list.tender.ID == currentFav" @click="showMenu(list.tender.ID)">
                           </div>
                           <div class="tender-header__tags" @click="showMenu(list.tender.ID)">
                              <!-- <div class="tender-header__tag" v-if="list.status == 4">
                                 Проанализировать
                              </div>
                              <div class="tender-header__tag" v-if="list.status == 3">
                                 Участвовать
                              </div>
                              <div class="tender-header__tag" v-if="list.status == 2">
                                 Выиграли
                              </div>
                              <div class="tender-header__tag" v-if="list.status == 1">
                                 Субподряд
                              </div> -->
                              <div class="tender-header__tag">
                                 {{ currentFavStatus(list.status).name }}
                              </div>
                              <ul class="tender-footer__sublist" :class="{
                                 showFav: list.tender.ID == currentFav,
                              }">
                                 <li v-for="item in favObjects" :key="item" @click="AddFavorite(list, item.id, index)"
                                    v-show="item.id != list.status">
                                    {{ item.name }}
                                 </li>
                              </ul>
                           </div>
                           <div class="tender-header__info">
                              <ul class="tender-header__list" v-if="list.tender.Etp">
                                 <li class="tek">
                                    {{ list.tender.Etp }}
                                 </li>
                                 <li class="rus" v-if="list.tender.Fz">
                                    {{ list.tender.Fz }}-ФЗ
                                 </li>
                                 <!-- <li class="auk">АУК</li>  -->
                              </ul>
                              <div class="tender-header__place tenders__text_small" v-if="list.tender.Region">
                                 {{ list.tender.Region }}
                              </div>
                           </div>
                           <div class="tender-header__name" v-if="
                              list.tender.Date && list.tender.TenderNumOuter
                           ">
                              Тендер №{{ list.tender.TenderNumOuter }} от
                              {{ list.tender.Date }}
                           </div>
                        </header>
                        <main class="tender-main">
                           <div class="tender-main__info" @click="
                              RedirectToTender(
                                 '/profile/tender/' + list.tender.ID
                              )
                              ">
                              <p class="tender-main__text big" v-if="list.tender.TenderName"
                                 v-html="list.tender.TenderName"></p>
                              <p class="tender-main__text small" v-if="list.tender.Category">
                                 {{ list.tender.Category }}
                              </p>
                           </div>
                           <div class="tender-main__prices">
                              <div class="tender-main__price" v-if="list.tender.Price">
                                 {{ numberWithSpaces(list.tender.Price) }} ₽
                              </div>
                           </div>
                        </main>
                        <footer class="tender-footer">
                           <div class="tender-footer__price" v-if="list.tender.Price">
                              {{ numberWithSpaces(list.tender.Price) }} ₽
                           </div>
                           <div class="tender-footer__info">
                              <div class="tender-footer__time tenders__text_small" v-if="list.tender.EndTime">
                                 <span class="light">Завершение приема заявок (МСК)
                                    {{ list.tender.EndTime }}
                                 </span>
                                 <!-- <span class="dark">10:00</span> -->
                              </div>
                              <div class="tender-footer__remind tenders__text_small" v-show="list.tender.EndTime"
                                 :class="{
                                    'middle-timer':
                                       CheckData(list.tender.EndTime) == 2,
                                    'success-timer':
                                       CheckData(list.tender.EndTime) == 1,
                                    end_timer:
                                       CheckData(list.tender.EndTime) == 0,
                                 }">
                                 {{ GetData(list.tender.EndTime) }}
                              </div>
                           </div>
                           <div class="tender-footer__actions">
                              <!-- <div class="tender-footer__hide tenders__text_small">Скрыть</div> -->

                              <div class="tender-footer__favorites tender-footer__favorites_clear tenders__text_small">
                                 <span @click="UnFav(list.id, index)">Убрать из избранного</span>
                              </div>
                           </div>
                        </footer>
                     </li>
                  </ul>

                  <!--                   <button class="tenders-main__btn" id="tendersShowMore">
                     Показать еще
                  </button> -->
               </div>
               <div class="tenders-footer" ref="pagination">

                  <ul class="tenders-footer__pages" v-if="page_max > 0 && page_max != 1">
                     <!-- <li v-show="current_page - 1 != 0" @click="setPage(current_page - 1)">
                        <a>
                           <p>{{ current_page - 1 }}</p>
                        </a>
                     </li> -->
                     <li v-for="list in filterNumbers" v-show="list != page_max" :key="list"
                        :class="{ active: current_page == list.num }" @click="setPage(list.num)">
                        <a>
                           <p>{{ list.num }}</p>
                        </a>
                     </li>
                     <!-- <li>
                        <p>...</p>
                     </li>
                     <li @click="setPage(page_max)" :class="{ active: current_page == page_max }">
                        <a>
                           <p>{{ page_max }}</p>
                        </a>
                     </li> -->
                  </ul>

               </div>
               <!--                <div class="tenders-footer">
                  <ul class="tenders-footer__pages">
                     <li class="active">
                        1
                     </li>
                     <li>
                        2
                     </li>
                     <li>
                        3
                     </li>
                     <li>
                        4
                     </li>
                     <li>
                        5
                     </li>
                     <li>
                        6
                     </li>
                     <li>
                        ...
                     </li>
                     <li>
                        2 821 000
                     </li>
                  </ul>
                  <div class="tenders-footer__search">
                     <span>На страницу</span>
                     <input type="number">
                     <button>Перейти</button>
                  </div>
               </div> -->
            </div>
         </div>
      </section>
      <section v-else>
         <div class="container not__fav">
            <div class="pos_center">
               <h3 @click="RedirectTo('Search')">
                  На данный момент вы не добавили ни одной закупки. Перейдите в
                  раздел «поиск» для выбора интересующих вас закупок
                  <span>
                     <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M6.812 15.7L12.74 9.512H0.65V6.288H12.74L6.812 0.0999991H11.362L19.162 7.9L11.362 15.7H6.812Z"
                           fill="#FFD954" />
                     </svg>
                  </span>
               </h3>
            </div>
         </div>
      </section>
   </main>
</template>

<style type="scss">
.not__fav {
   display: flex;
   height: 509px;
   justify-content: center;
   align-items: center;
   flex-shrink: 0;
   align-self: stretch;
}

.pos_center {
   display: flex;
   justify-content: center;
   align-items: center;
   flex: 1 0 0;
   align-self: stretch;
   max-width: 620px;
}

.pos_center h3 {
   display: flex;
   align-items: center;
   gap: 12px;
   color: #fff;
   font-size: 26px;
   font-style: normal;
   font-weight: 500;
   line-height: 28px;
   /* 107.692% */
   letter-spacing: -0.52px;

   cursor: pointer;
}

.tender-header__tag {
   cursor: pointer;
   position: relative;
}

.tender:hover {
   background-color: #2d2f33 !important;
}

.tender-footer__sublist.showFav {
   z-index: 1;
   transform: translateY(50px) scale(1);
   left: 0;
   right: inherit !important;
}
</style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import moment from "moment";
import { toast } from "bulma-toast";

import ProfileHeader from "@/components/Header/ProfileHeader";

export default {
   name: "Favorites",
   directives: { mask },
   data() {
      return {
         favoriteItemsCount: 0,
         favoriteItems: [],
         currentFav: 0,
         curStatus: 0,
         alert: false,
         pageOffset: 0,
         pageLimit: 12,
         user: {},

         statusAr: [],
         favObjects: [
            {
               id: 4,
               name: "Проанализировать",
            },
            {
               id: 3,
               name: "Участвовать",
            },
            {
               id: 2,
               name: "Выиграли",
            },
            {
               id: 1,
               name: "Субподряд",
            },
         ],
         dataExcel: [],
         json_fields: {
            Наименование: "tender.TenderName",
            Категория: "tender.Category",
            Заказчик: "tender.Customer",
            Регион: "tender.Region",
            Площадка: "tender.Etp",
            Категория: "tender.Category",
            "Дата окончания": "tender.EndTime",
            ФЗ: "tender.Fz",
            "Начальная цена": "tender.Price",
            "Номер тендера": "tender.TenderNumOuter",
            // City: "city",
            // Telephone: "phone.mobile",
         },
      };
   },
   mounted() {
      this.GetMeInfo();
      this.getFavorFilters()
   },
   methods: {
      currentFavStatus(id) {
         let obj = this.favObjects[0]
         this.favObjects.forEach(item => {
            if (item.id == id) {
               obj = item
            }
         })
         return obj
      },
      async getFavorFilters() {
         let { data } = await axios('/api/v1/favorite/statuses')
         let formatData = data.map(item => {
            return {
               id: item.status,
               name: item.status_name
            }
         })
         let arr = this.favObjects.concat(formatData)
         this.favObjects = arr;
         console.log('favor', this.favObjects);
      },
      async GetMeInfo() {
         await axios
            .get("/api/v1/me/")
            .then((response) => {
               this.user = response.data;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      RedirectToTender(value) {
         this.$router.push(value);
      },
      RedirectTo(value) {
         this.$router.push({ name: value });
      },
      openFav(value) {
         if (this.currentFav != value) {
            this.currentFav = value;
         } else {
            this.currentFav = 0;
         }
      },
      numberWithSpaces(x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      GoToRef(val) {
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
      async UnFav(list_id, index, not_splice = false) {
         await axios
            .delete(`/api/v1/favorite/delete/${list_id}/`)
            .then((response) => {
               if (!not_splice) this.favoriteItems.splice(index, 1);
               // this.total = this.total - 1
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetFavoriteInfo() {
         await axios
            .get(`/api/v1/favorite/?limit=${this.pageLimit}&offset=${this.pageOffset}`)
            .then((response) => {
               this.favoriteItemsCount = response.data.count;
               this.favoriteItems = response.data.results;
               this.dataExcel = response.data.results;
               console.log('favoriteItems', response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetFavoriteInfoFilter(status) {
         await axios
            .get(`/api/v1/favorite/filter?status=${status}`)
            .then((response) => {
               if (response.data.count == 0)
                  this.alertShow("У вас нет избранных по этой метке!");
               else this.favoriteItems = response.data.results;
               console.log(response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async AddFavorite(list, value, index) {
         const dataForm = {
            get_tenderID: list.get_tenderID,
            user: this.user.user,
            tender: list.tender,
            status: value,
         };

         await axios
            .post("/api/v1/favorite/post/", dataForm)
            .then((response) => {
               this.currentFav = 0;

               var removed = this.favoriteItems.splice(index, 1, response.data);
               this.UnFav(list.id, index, true);

               // if(this.statusAr.length == 0) this.GetFavoriteInfo()
               // else this.GetFavoriteInfoFilter(this.statusAr)
            })
            .catch((error) => {
               console.log(error);
            });
      },
      alertShow(value) {
         toast({
            message: value,
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
         });
      },
      showMenu(value) {
         if (this.currentFav != value) {
            this.currentFav = value;
         } else {
            this.currentFav = 0;
         }
      },
      CheckData(value) {
         var dateB = moment(String(value), "DD-MM-YYYY");
         var dateC = moment();
         var dif = Number(dateB.diff(dateC, "days"));

         if (dif >= 5) {
            return 1;
         } else if (dif < 5 && dif > 0) {
            return 2;
         }

         if (dif < 0 || dif == 0) {
            return 0;
         }
      },
      GetData(value) {
         var dateB = moment(String(value), "DD-MM-YYYY");
         var dateC = moment();

         var dif = dateB.diff(dateC, "days");
         if (dif < 0) {
            return "завершено";
         } else if (dif == 0) {
            return "сегодня завершится";
         } else {
            return (
               "до завершения:" + " " + dateB.diff(dateC, "days") + " " + "д."
            );
         }
      },
      selectSort(option) {
         console.log(option);
      },
      setPage(value) {
         this.pageOffset = value * this.pageLimit - this.pageLimit;
         this.GetFavoriteInfo()
      },
   },
   computed: {
      page_max() {
         return Math.ceil(this.favoriteItemsCount / this.pageLimit);
      },
      current_page() {
         return ((this.pageOffset / this.pageLimit) + 1);
      },
      filterNumbers() {
         let range = [];

         // if (this.current_page == this.page_max) {
         //    range.push({ num: 1 });
         //    range.push({ num: 2 });
         //    // range.push({ num: 3 })
         //    // range.push({ num: 4 })
         //    // range.push({ num: 5 })
         // } else {
         //    for (
         //       let i = Number(this.current_page);
         //       i <=
         //       Math.min(
         //          Number(this.current_page) + this.pageLimit - 1,
         //          this.page_max
         //       );
         //       i++
         //    ) {
         //       if (i != 0) {
         //          range.push({
         //             num: i,
         //          });
         //       }
         //    }
         // }
         // for (
         //    let i = Number(this.current_page);
         //    i <=
         //    Math.min(
         //       Number(this.current_page) + this.pageLimit - 1,
         //       this.page_max
         //    );
         //    i++
         // ) {
         //    if (i != 0) {
         //       range.push({
         //          num: i,
         //       });
         //    }
         // }
         for (let i = 0; i <= this.page_max; i++) {
            if (i != 0) {
               range.push({
                  num: i,
               });
            }
         }
         return range;
      },
   },
   components: {
      "profile-head": ProfileHeader,
   },
   watch: {
      statusAr(value) {
         console.log(value.length);
         if (value.length > 0) this.GetFavoriteInfoFilter(value);
         else this.GetFavoriteInfo();
      },
   },
   created() {
      this.GetFavoriteInfo();
   },
};
</script>
